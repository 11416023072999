<template>
  <div style="width: 100%;border: 1px solid black;height: Calc(100% / 5);display: flex;position: relative">
    <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 5%;top: 50%;transform: translateY(-50%)" :signature="'4s'"></vue-playing-card>
    <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 10%;top: 50%;transform: translateY(-50%)" :signature="'5s'"></vue-playing-card>
    <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 15%;top: 50%;transform: translateY(-50%)" :signature="'6s'"></vue-playing-card>
    <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 20%;top: 50%;transform: translateY(-50%)" :signature="'7s'"></vue-playing-card>
    <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 25%;top: 50%;transform: translateY(-50%)" :signature="'8s'"></vue-playing-card>
    <div style="height: 100%;position: relative">
      <div style="position: absolute;top: 50%;transform: translateY(-50%);left: 50%">
        200
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  props:{

  },

  computed:{

  }
}
</script>