<template>
  <div class="mainComp">
     <div class="mainComp_card">
       <SkrineCard></SkrineCard>
     </div>
     <div class="mainComp_card"></div>
  </div>
</template>
<style lang="scss" scoped>
.mainComp{
  .neum{
    font-size: 48px;
    color: #888; /* Text color */
    padding: 20px;

    display: inline-block;
  }
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  background: var(--primary_bg);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .mainComp_card{
    background: #f8f4f2;
    width: 45%;
    height: 90%;
    border: 1px solid var(--grey_1);
    border-radius: 1%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05); /* Tieň */
    justify-content: space-evenly;
    padding: 2vh;

  }
}
</style>
<script >
import SkrineCard from "@/components/skrine/SkrineCard.vue";
export  default {
  components:{SkrineCard,},
}

</script>