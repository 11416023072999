<template>
  <div style="width: 100%;height: Calc(100%);display: flex">
    <div style="width: 50%;height: Calc(100%);display: flex;position: relative">
      <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 10%;top: 50%;transform: translateY(-50%)" cover></vue-playing-card>
      <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 20%;top: 50%;transform: translateY(-50%);font-size: 50px" :signature="returnedValue+'s'"></vue-playing-card>
      <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 30%;top: 50%;transform: translateY(-50%)" :signature="returnedValue+'d'"></vue-playing-card>
      <vue-playing-card style="height: 80%;width: auto;position: absolute;left: 40%;top: 50%;transform: translateY(-50%)" :signature="returnedValue+'c'"></vue-playing-card>
      <div style="height: 80%;position: absolute;left: 50%;top: 50%;transform: translateY(-50%);aspect-ratio: 12/18;background: white;border-radius: 5%;border: 1px solid black">
        <div style="width: 100%;height: 100%;position: relative">
          <div style="position:absolute;color: #f62222;font-size: 5vh;left: 50%;top: 50%;line-height: 5vh; transform: translate(-50%, -50%)">♥</div>
<!--          <div style="position:absolute;color: #f62222;font-size: 4vh;right: 0.3vh;bottom: 0;line-height: 4vh;transform: rotateZ(180deg)">♥</div>-->
        <div style="position: absolute;top: 0;left: 0.5vh;font-size: 5vh; line-height:5vh;font-weight: bold;color: #f62222">
          {{returnedValue==='T'? '10':returnedValue}}
        </div>
          <div style="position: absolute;bottom: 0;right: 0.5vh;font-size: 5vh; line-height:5vh;font-weight: bold;color: #f62222;transform: rotateZ(180deg)">
          {{returnedValue==='T'? '10':returnedValue}}
        </div>
<!--          <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);font-size: 6vh;font-weight: bold;color: #f62222">-->
<!--          {{returnedValue==='T'? '10':returnedValue}}-->
<!--        </div>-->
        </div>
      </div>

    </div>
    <div style="height: 100%;position: relative;width: 40%;z-index: 5">
      <div style="position: absolute;top: 50%;transform: translateY(-50%);width: 100%">
            <v-row style="height: 100%;position: relative;z-index: 5" justify="center" align="center">
              <v-col v-if="cardObject.active===true" cols="auto" class="px-2 pb-5 sumNumber">
                {{ Math.floor(cardObject.value)}} €
              </v-col>
              <v-col v-else cols="auto" class="px-2 pb-5 sumNumber">
                <span class="strikethrough">{{  Math.floor(cardObject.value)}} €</span>
              </v-col>
              <div style="position: absolute;bottom: 0;color: white;z-index: 5; line-height: 20px;font-size: calc(0.8vw + 0.8vh)!important;">PLO
                {{ cardObject.plo  }}%
              </div>
            </v-row>

      </div>

    </div>

  </div>

</template>
<script>
export default {
  props:{
    cardIndex:{
      default:'A'
    },
    cardObject:{}
  },

  computed:{
    returnedValue(){
      let indexOfCard = this.cardIndex
      if(this.cardIndex==='10'){
        indexOfCard= 'T'
      }
      return  indexOfCard
    }
  }
}
</script>
<style>
.sumNumber{
  font-size: 6vh!important;
  color: white!important;
}
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  color: #cc1313;
  border-top: 7px solid;
  border-color: inherit;

  -webkit-transform:rotate(40deg);
  -moz-transform:rotate(40deg);
  -ms-transform:rotate(40deg);
  -o-transform:rotate(40deg);
  transform:rotate(40deg);
}.strikethrough:after {
   position: absolute;
   content: "";
   left: 0;
   top: 50%;
   right: 0;
   color: #cc1313;
   border-top: 7px solid;
   border-color: inherit;

   -webkit-transform:rotate(-40deg);
   -moz-transform:rotate(-40deg);
   -ms-transform:rotate(-40deg);
   -o-transform:rotate(-40deg);
   transform:rotate(-40deg);
 }
.vue-playing-card{
  font-size: 30px!important;
}
</style>