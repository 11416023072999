<template>
  <div id="skrineCard">
    <div class="customColumn">

      <v-progress-circular
          :rotate="360"
          :size="100"
          :width="15"
          :value="progres"
          color="teal"
      >
        {{ progres }}
      </v-progress-circular>

      countodown{{ filter.dateFrom }} <br>
      CZ{{ rozpoctyCZ }} <br>
      spolu{{ rozpocty }} <br>
    </div>
    <div class="customColumn"></div>
    <div class="customColumn">
      <rozpocty-conversion
          :posuvny-celkom="posuvnySystemCelkom"
          :posuvny-ukonceny="posuvnySystemUkonceny"
          :vnutro-celkom="vnutroCelkom"
          :vnutro-ukonceny="vnutroUkonceny"
          :vstavana-celkom="vstavanaCelkom"
          :vstavana-ukonceny="vstavanaUkonceny"
          style="width: 30%"></rozpocty-conversion>
      <MapSVG :rozpocty-s-k="rozpoctySKNewValue"
              :rozpocty-c-z="rozpoctyCZNewValue"
              :rozpocty-celkom="rozpoctySKNewValue+rozpoctyCZNewValue"
              style="width: 65%"/>
    </div>
  </div>

</template>
<script>
import RozpoctyConversion from "@/components/common/RozpoctyConversion.vue";
import MapSVG from "@/components/common/MapSVG.vue";
import axios from "axios";
import store from "@/store";

export default {
  components: {RozpoctyConversion, MapSVG},
  data() {
    return {
      rozpoctySKNewValue:0,
      rozpoctyCZNewValue:0,


      intervalId: null,
      intervalDuration: 10000,
      intervalId2: null,
      intervalDuration2: 100,
      progres:0,
      odpocet: 100,
      rozpocty: 0,
      rozpoctySK: 0,
      rozpoctyCZ: 0,
      vstavanaCelkom:0,
      vstavanaUkonceny:0,
      posuvnySystemCelkom:0,
      posuvnySystemUkonceny:0,
      vnutroCelkom:0,
      vnutroUkonceny:0,

      statsSK: [],
      statsCZ: [],
      filter: {
        dateFrom: "",
        dateTo: "",
        status: [
          "Vytvorena",
          "Odkonzultovana",
          "V rozvoze"
        ],
        limit: 7000000,
        selectedCountries: [
          "SK",
          "CZ"
        ]
      },
    }
  },
  methods: {
    dateForomater(date) {
      let format = date.toISOString().substring(0, 10)
      return format
    },
    setOdpocet() {
      this.odpocet = this.odpocet - 1
      this.progres++
    },
    update() {
      this.init()
      this.odpocet = 100;
      this.progres = 0;
    },
    async fetchOrders() {
      await axios.post("https://backendnove.online-skrine.sk/listOrders", {
        token: 'o;aopfhfiahdklfiak;hfk;a',
        filter: this.filter,
        skip: 0
      })
          .catch(error => {
            console.error('Error:', error);
          })
    },
    async fetchStatsSK() {
      let newFilter = structuredClone(this.filter)
      newFilter.selectedCountries = ['SK']
      await axios.post( "https://backendnove.online-skrine.sk/listStats", {
        token: 'o;aopfhfiahdklfiak;hfk;a',
        filter: newFilter,
      })
          .then(response => {
            if (response && response.data) {
              this.statsSK = response.data
              response.data.forEach((stat) => {
                this.rozpocty = this.rozpocty + stat.rozpocet
                this.rozpoctySK = this.rozpoctySK + stat.rozpocet
              })
            }
          })
          .catch(error => {
            console.error('Error:', error);
          })
    },
    async fetchStatsCZ() {
      let newFilter = structuredClone(this.filter)
      newFilter.selectedCountries = ['CZ']
      await axios.post( "https://backendnove.online-skrine.sk/listStats", {
        token: 'o;aopfhfiahdklfiak;hfk;a',
        filter: newFilter,
      })
          .then(response => {
            if (response && response.data) {
              this.statsCZ = response.data
              response.data.forEach((stat) => {
                this.rozpocty = this.rozpocty + stat.rozpocet
                this.rozpoctyCZ = this.rozpoctyCZ + stat.rozpocet
              })
            }
          })
          .catch(error => {
            console.error('Error:', error);
          })
    },
    async init() {
      this.filter.dateFrom = this.filter.dateTo = this.dateForomater(new Date())
      this.rozpocty = 0
      this.rozpoctySK = 0
      this.rozpoctyCZ = 0
      this.statsSK = []
      this.statsCZ = []
      try {
        await this.fetchOrders()
        await this.fetchStatsSK()
        await this.fetchStatsCZ()

      }
      finally
      {
        this.updateValues()
      }
    },
    updateValues(){
      let vstavanaObjekty = this.stats.filter(objekt => objekt.type === 'vstavana')
      let posuvnyObjekty = this.stats.filter(objekt => objekt.type === 'posuvnySystem')
      let vnutroObjekty = this.stats.filter(objekt => objekt.type === 'vnutro')
      let vstavanaCelkomNewValue = vstavanaObjekty.reduce((acc, objekt) => acc + objekt.step1, 0)
      let vstavanaUkoncenyNewValue = vstavanaObjekty.reduce((acc, objekt) => acc + objekt.rozpocet, 0)
      let posuvnySystemCelkomNewValue = posuvnyObjekty.reduce((acc, objekt) => acc + objekt.step1, 0)
      let posuvnySystemUkoncenyNewValue = posuvnyObjekty.reduce((acc, objekt) => acc + objekt.rozpocet, 0)
      let vnutroCelkomNewValue = vnutroObjekty.reduce((acc, objekt) => acc + objekt.step1, 0)
      let vnutroUkoncenyNewValue = vnutroObjekty.reduce((acc, objekt) => acc + objekt.rozpocet, 0)
      if (this.vstavanaCelkom === 0 || this.vstavanaCelkom!==vstavanaCelkomNewValue){
        this.vstavanaCelkom = vstavanaCelkomNewValue
      }
      if(this.vstavanaUkonceny === 0 || this.vstavanaUkonceny!==vstavanaUkoncenyNewValue){
        this.vstavanaUkonceny = vstavanaUkoncenyNewValue
      }
      if(this.posuvnySystemCelkom === 0 || this.posuvnySystemCelkom!==posuvnySystemCelkomNewValue){
        this.posuvnySystemCelkom = posuvnySystemCelkomNewValue
      }
      if(this.posuvnySystemUkonceny === 0 || this.posuvnySystemUkonceny!==posuvnySystemUkoncenyNewValue){
        this.posuvnySystemUkonceny = posuvnySystemUkoncenyNewValue
      }
      if(this.vnutroCelkom === 0 || this.vnutroCelkom!==vnutroCelkomNewValue){
        this.vnutroCelkom = vnutroCelkomNewValue
      }
      if(this.vnutroUkonceny === 0 || this.vnutroUkonceny!==vnutroUkoncenyNewValue){
        this.vnutroUkonceny = vnutroUkoncenyNewValue
      }
      if (this.rozpoctyCZ!==this.rozpoctyCZNewValue){
        this.rozpoctyCZNewValue=this.rozpoctyCZ
      }
      if (this.rozpoctySK!==this.rozpoctySKNewValue){
        this.rozpoctySKNewValue=this.rozpoctySK
      }
    }
  },
  computed: {
    stats() {
      let result = [...this.statsSK, ...this.statsCZ]
      return result
    },
  },
  mounted() {
    this.init()
    this.intervalId = setInterval(() => {
      this.update(); // Spustit funkci
    }, this.intervalDuration);
    this.intervalId2 = setInterval(() => {
      this.setOdpocet(); // Spustit funkci
    }, this.intervalDuration2);

  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },

}


</script>
<style lang="scss" scoped>
#skrineCard {
  height: 100%;
  width: 100%;

  .customColumn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 33%;
  }
}
</style>