<style scoped>
.conversion_main {
  width: 100%;

  .cardContainer {
    height: Calc(100% / 4);
    padding: .5vh;

    .card {
      background: var(--primary_bg);
      color:  #ae9389;
      height: 100%;
      border: 1px solid var(--grey_1);
      border-radius: 1%;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05); /* Tieň */
      padding: .5vh;
      h4{
        font-size: 2vh;
        line-height: 2vh;
      }
      h5{
        font-size: 1.7vh;
        line-height: 1.7vh;
        padding-top: 1vh;
        text-align: center;
      }
    }
  }

}
</style>
<template>
  <div class="conversion_main">
    <div class="cardContainer">
      <div class="card">
        <h4>Celkom</h4>
        <h5>{{ vstavanaUkonceny + posuvnyUkonceny + vnutroUkonceny }}  / {{vstavanaCelkom + posuvnyCelkom + vnutroCelkom}} ({{ (((vstavanaUkonceny + posuvnyUkonceny + vnutroUkonceny)/(vstavanaCelkom + posuvnyCelkom + vnutroCelkom))*100).toFixed(0) }}%)</h5>
      </div>
    </div>
    <div class="cardContainer">
      <div class="card">
        <h4>Vstavané skrine</h4>
        <h5>{{ vstavanaUkonceny }} / {{ vstavanaCelkom }} ({{ ((vstavanaUkonceny/vstavanaCelkom)*100).toFixed(0) }}%)</h5>
      </div>
    </div>
    <div class="cardContainer">
      <div class="card">
        <h4>Posuvný systém</h4>
        <h5>{{ posuvnyUkonceny }} / {{ posuvnyCelkom }} ({{ ((posuvnyUkonceny/posuvnyCelkom)*100).toFixed(0) }}%)</h5>
      </div>
    </div>
    <div class="cardContainer">
      <div class="card">
        <h4>Šatník</h4>
        <h5>{{ vnutroUkonceny }} / {{ vnutroCelkom }} ({{ ((vnutroUkonceny/vnutroCelkom)*100).toFixed(0) }}%)</h5>
      </div>
    </div>
  </div>
</template>
<script >
export default {
  props:{
    posuvnyCelkom:{},
    posuvnyUkonceny:{},
    vnutroCelkom:{},
    vnutroUkonceny:{},
    vstavanaCelkom:{},
    vstavanaUkonceny:{},
  }
}
</script>