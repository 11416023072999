import Vue from 'vue'
import VueRouter from 'vue-router'
import newHome from '../views/newHome3.vue'
import dash from '../views/MainPage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: newHome
  },
  {
    path: '/maros',
    name: 'dash',
    component: dash
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:routes,
})

export default router
