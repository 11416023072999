<style scoped>
.mapSVGH3{
  font-size: 3vh;
  line-height: 3vh;
}
.stroke{
  stroke: #7c7c7c;
}
.svg{
  height: Calc(100%);
  width: 100%;
}

.rozpoctyCZ_map{
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 6vh;
  font-weight: bold;
  color: #e7dbd8;
}
.rozpoctySK_map{
  position: absolute;
  top: 70%;
  left: 68%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 6vh;
  font-weight: bold;
  color: #ae9389;
}
.slovakia{
  fill: #e7dbd8;
}
.czech{
  fill: #d5b8ae;
}
</style>


<template>
  <div style="height: 100%;width: 100%;position: relative">
    <div class="rozpoctyCZ_map" >{{ rozpoctyCZ }}</div>
    <div class="rozpoctySK_map" >{{ rozpoctySK }}</div>
    <svg class="svg" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 1235 611" style="enable-background:new 0 0 1235 611;" xml:space="preserve">
      <g id="XMLID_18_" >

	<path  id="XMLID_7_" class="stroke slovakia" d="M1210.7,407.2L1210.7,407.2l-0.1,0.3l-0.8,2.2l-0.1,1.4l0.2,1.5l0.2,6.1l-0.3,1.2l-0.8,2l-1,1.5
		l-1.1,0.9l-0.9,1.2l-0.4,2.4l0.1,3.8l-0.7,3.8l-1.4,3.3l-1.8,2.5l-1.4,0.9l-3,1l-1.4,1l-0.9,1.3l-1.2,2.8l-0.7,1.3l-7.4,6.2
		l-1.7,2.9l-0.2,3.6l1.3,7.2l-0.4,2.8l-1.3,2.9l-0.1,12.8l-2.2,2.9l-2,1.6l-2.1,0.6l-6.8-0.7l-2.1,0.1l-2.1,0.7l-5.9,0.3l-1.7,0.7
		l-3.5,2.1l-4.9,0.8l-5.9,3.1l-3.5,0.3l-3.6-1.3l-3-2.3l-2.7-3.3l-6.4-10.1l-0.9-1.9l-1.5-7.3l-1-2l-1.9-0.5l-4.2,0.1l-1.8-0.9
		l-0.8-1.2l-1-3.4l-0.8-1.5l-1-0.9l-2.1-0.8l-3.8-2.4l-1.7-0.5l-5.9,1.9l-3.9,0.1l-1.9,0.4l-2.2,1.4l-1,1.7l-0.6,1.9l-1.3,2l-1.7,1
		l-1.3-0.6l-1.4-1.2l-2.1-0.9l-3.8,0.9l-8.8,4.4l-2.9-0.7l-2.3-2.3l-3.2-1.5l-3.4-0.6l-2.8,0.2l-4.1-0.4l-6.2-3.9l-3.6-0.4l-1.6-0.4
		l-3.4-3.2l-1.8-1l-1.8,0l-24.1,5.8l-7.1,0.5l-3.3,1.4l-0.1,1.3l0,1.5l0.2,3.1l0.1,0.6l0,0.6l0,0.6l-0.1,0.6l-1.5,2.4l-0.3,0.3
		l-3.5,3.8l-1.7,2.3l-1.3,2.8l-4.4,14l-2.4,5.1l-2.8,4.5l-3.3,3.4l-2.6,1.4l-1.4-0.6l-1.3-1.5l-2.3-1.2l-1.3,0.6l-3.4,3.3l-1.9,0.9
		l-2-0.2l-1.2-0.4l-1,0.2l-1.4,1.8l-0.4,1.2l-0.6,3.2l-0.8,1.6l-0.9,0.8l-2.2,0.9l-4.5,2.9l-0.4,0.2l-4.3,1.4l-2.6,1.7l-5.2,5
		l-2.7,1l-2.4-0.9l-4.3-4.1l-2.8-0.9l-4.1,1.6l-1.1-0.1l-1.1-1.7l0.3-1.5l0.6-1.5l-0.1-1.7l-2-2.5l-2.7-0.5l-5.2,1.1l-1.1-0.6
		l-2.5-3.2l-1.3-1.1l-1.1-0.3l-1.2,0l-10.5,2.9l-2,1.2l-1.2,2.6l-1.1,6.8l-1.4,2.8l0,0.1l0,0.1l0,0.1l0,0.1l0.2,1l0.1,1l-0.1,0.9
		l-0.2,0.9l-6.1,4.5l-15.4-0.3l-6.8,4.5l-1.2,0.3l-14.1-1.8l-6.8,1l-2.2-0.1l-2.6-0.1l-1.7,0.8l-5.5,1.3l-10.8,2.5l-2,1.7l-3,6.6
		l-1.1,1l-2.2,0.4l-1,0.6l-1.5,1.9l0.2,0.6l0.8,0.7l0.4,2l-1.1,7.2l-0.3,3.7l0.7,3.3l3.3,3.4l4.4,3.3l-0.2,0.1l-2.8,1l-2.6,0.7
		l-2,1.5l-3.8,4.5l-2.8,1.8l-3.3,0.4l-3.4-0.7l-4.1-1.9l-5.1-0.4l-23.3,2.8l-8.5,3.6l-4.2,0.4l-14-1.5l-26.1,1.7l-6.6,0.4l-9.5-2.7
		l-2.6-1.4l-4.8-2.7l-1.2-1.4l-0.9-1.8l-2.1-2.1l-2.4-1.8l-1.7-0.9l-1.3,0.2l-1.1,0.6l-1.2,0l-1.4-1.5l-3.8-6l-1.1-0.7l-2.9-0.6
		l-1.2-0.5l-1.1-1.1l-11.7-16.2l-3.6-3.1l-7.4-1.2l-1.2-0.3l-4.7-1.4l-4.1-0.9l-4.2,2.6l-2.7-2.5l-3.7-1.4l-2.6-1.5l0.6-2.9
		l-1.4-1.2l-0.4-0.3l0.6-3.3l0.6-1.8l1.2-1.5l-1.5-1.6l-0.5-1l-1.7-3.2l-1.2-0.8l-1.8-0.3l-1.6-1l-2.9-3.3l-0.8-2.7l0-3.8l-0.5-3.2
		l-1.8-6.3l0-0.8l0.2-2l-0.1-0.8l-0.4-0.6l-0.7-0.3l-1.3-1.2l-1-0.5L590,508l-0.4-1l-0.1-0.8l-0.4-1l-0.4-0.9l-0.3-0.4l-0.5-0.4
		l0.2-0.9l0.4-1l0.3-0.8l-0.5-1.4l-1.2-1.3l-1.2-1l-0.6-0.4l-2.3-0.3l-0.9-0.6l-0.4-1.1l0.1-1.9l0.2-0.8l0.3-0.5l0.3-1.1l1-9.5
		l0.5-2.5l1.2-2.4l3-4.4l0.6-2.4l0.8-1.7l1.9-1.6l1.9-2l0.2-0.9l0.6-2.2l-1.1-8.3l0.3-3.4l1.8-2.3l1.3-2.5l5.8-17.2l2.8-4.9l3.9-3.5
		l1.6-2.1l0.7-3.4l1-1.7l6.1-4.6l5.1-1.1l5.5,1.5l13,6.8l2-0.4l2.2-1.5l2.1-1.4l2.7-0.8l1.7,0.8l3.5,3.8l4.2,0.7l12.6-5.1l9.2-3.8
		l2-1.7l3.9-7l1.8-1.6l2.8-0.5l2.4,0.4l2.2-0.1l2.1-2.1l0.9-2.5l0.9-5.5l0.7-2.7l1.5-3.1l2.4-1.6l2.8-0.4l6.1,0.5l3.8-1.8l3.4-3.2
		l2.4-4.1l0.8-3.9l-0.1-8l0-1.1l0.4-4.8l1.4-5.9l2.1-5.5l2.7-4.6l3.4-3.3l15.3-6.2l4.2-3.4l2.7-2.2l0.2-6.4l0.1-2.2l3.3,0.8l2.6-1.7
		l4.8-6.1l3.7-2.1l0.9-1l0.6-1.6l0.3-3.4l0.6-1.5l2.3-1.5l5,0.8l3.2-1.8l0.8-0.2l0.8,0.2l3.7,1.9l3.3,1l3.2-0.2l4.7-4.5l2.1-0.8
		l4.7-0.1l11.3,1.1l3.3,2.1l-1,3l0.1,2.6l0.6,2.4l0.3,2.7l-0.5,5.9l0.6,2l2.2,0.4l2.9-0.2l4.4-2.7l2.5-0.6l1,0.4l2.3,1.7l1.1,0.6
		l1.2,0.1l2.9-0.6l3.5-1.5l0.8-1.4l0.3-2.3l0.8-3.5l0.3-0.1l1.4,0l0.5-0.4l0.3-1.2l-0.2-0.9l-0.4-0.5l-0.2-0.2l1.8-5.9l1.6-2.6
		l1.7-1.6l1.9-0.9l2.2-0.5l3.5,0l1.2-0.2l1.6-0.7l0.9-0.8l9.9-11.3l0.4-0.9l0.7-0.3l0.6,0.3l1,0.4l1.9,3.5l0.9,0.9l2.7,1.8l1.4,3.6
		l2.1,9.1l1.8,5.8l0.6,1.3l2,1.6l2.4,0.7l4.6,0l-0.5,1.1l-0.2,1l0.1,2.8l-0.2,2.1l0,0.1l6.5,2.3l2.5,0.3l2.3-0.2l3.8-1.6l1,0.8l0,0
		l1.1,3.4l0.6,3l0.7,8.7l-0.2,0.7l-0.9,1.4l-0.1,0.7l0,0l0.4,0.6l1.6,1.2l0,0l0.2,0.4l0,0.1l0.9,0.9l0,0l0.2,0.8l-0.3,1l-0.7,0.4
		l-0.7,0.2l-0.5,0.4l-4.3,7.3l-0.5,2.4l0,0l1.5,2.1l2.9,1.1l5.2,0.4l2.5-1l1.6-1.7l2.2-2.4l2.1-1.6l3.6-0.4l3.2,1.7l5.9,5.7l3.8,1.9
		l2.2-1.8l1.2-4.5l0.6-6.3l1.4-1.8l0.8-2l0.6-2.1l2.2-5.1l0.6-0.9l0.3,0.3l2.5,0.3l1.1-1.1l2.5-3.1l1.7-1l8.8-0.8l0.6-0.8l0.8-2.5
		l0.6-2.6l0.2-1.7l0.4-1.1l1.2-0.9l1.4,0l4.7,1.8l5.9-0.2l0-3.1l1.8-0.4l9.6,5.1l2.5,0.6l2.7-1l1.3-1.3l1.8-2.3l1.2-0.7l1-0.1
		l2.5,0.4l4.3-0.7l1.8,0.3l10.1,12.5l1.8,1.4l2.6,0.4l1.9-0.1l1.8,0.3l2.1,1.6l1.9,2l1.8,1.4l2.1,0.4l2.7-1l2.5-2.2l3.7-5.6l2.3-2.4
		l1.8-0.4l2.4,0l2.1-0.4l0.9-1.7l-1.4-2.7l-2.6-1.7l-1.4-1.6l2.3-2.7l1.7-0.9l4.6-1l1.8,0.2l2.1,1.5l1.6,1.9l1.7,1.2l2.4-0.4l2-1.9
		l3.6-5.5l2.1-1.5l1.6,0.4l6.4,3.5l11.1,3.2l1.9,0l4.2-1l1.7,0.5l2.1-0.9l1.7-0.7l8.2-1l2.1,0.6l1.2,0.8l2.1,2.1l1.2,0.8l0.9,0.1
		l1.8-0.4l1.1,0.3l1.9,2l3.8,6.1l1.8,1.5l1.2-0.8l1.6-2l2-1.9l2.3-0.4l2.1,1.2l4.2,4l6.1,3.2l4.1,4l3.3,5.5l1.4,6.4l0.8,5.7l3.2,2.4
		l8.1,1.6l3.7,2.5l1.3,0.6l1.1,0.1l2.7-0.3l0.9,0.2l1.2,1.4l0,1.3l-0.3,1.2l0.3,1.1l0.8,0.8l5.3,1.7l6.3-0.3l2.4,1l5.8,6l4.2,1.3
		l8.9,0.4l3.9,2l-0.9,3l-0.8,4.1l-0.5,4.1l0,3l-1.8,1.6l-4.4,0.7l-2,1.6l-2.5,7.5l-1.4,3.2l-0.1,0.9l0,2.3l-0.2,1.1l-1.1,1.6
		l-2.7,2.4L1210.7,407.2L1210.7,407.2">

  </path>
</g>
      <g id="XMLID_2_">
	<path id="XMLID_3_" class="stroke czech"  d="M176.3,374.6L176.3,374.6l-0.7-0.1l-1.6,0.7l-1.1,1.9l-1.2,3l-2.7-1.3l-3.6-2.6l-3.3-3.1
		l-1.7-2.7l-0.1-4.9l-1.3-3l-4.9-5l-3.5-4.7l-1.4-1.2l-2.3-0.9l-6.9-0.3l-3.3-1.9l-1.8-2.9l-1.5-3.4l-10.8-14.6l-1.4-0.8l0,0
		l-3.7-4.4l-0.5-1.6l-0.2-1.7l-0.6-1.4l-2-0.8l-3.2-3.4l-1.7-1.3l-1.9-1l-4.4-1.2l-1.6,0.5l0.5,2.3l-2.2,0.3l-6.8-1l-2.3-0.8
		l-1.8-1.9l-3.8-8.4l-2.3-2.4l-5.3-2.5l-2.2-1.9l-1.3-2.6l-0.6-2.9l-0.3-5.8l-0.2-3l-0.9-0.7l-1.2,0.2l-1.3-0.4l-1.2-1.3l-0.9-1.3
		l-0.7-1.6l-0.7-2.1l-0.5-2.1l-0.5-3.9l-0.8-1.9l-1.2-1.3l-2.7-1.5l-1.2-1.4l0.1-0.4l0.5-1.4l-0.9-0.4l-0.3-0.2l1-2.2l-0.1-2.4
		l-1-1.9l-1.6-0.8l-3.8-1.8l-1.8-1.2l-1.7-1.5l-2.4-3.4l-1.2-2.3l-0.6-1.9l1.4-2.7l4.8-2l1.8-1.9l0.4-2.9l0.1-3.7l0.7-2.5l2.3,0.7
		l0.8-2.7l2.6-4.9l0.9-2.7l0.4-3.5l-0.7-1.3l-1.6-0.6l-4.7-2.8l0.1-1.9l0.8-2.4l-0.3-2.6l-1.9-1.8l-4.2-0.4l-1.8-1.7h-0.1l-17.4-9.3
		l-0.4-1.1l0-1.6l-0.2-1.8l-1-1.8l-1.7-1.4l-1.8-1.1l-1.6-1.5l-1-2.7l-0.1-2.7l0.8-4.1l-0.3-2.7l-0.9-2.1l-1.3-2l-2.8-3.1l-5.7-3.5
		l-1.2-2.1l1.4-2.2l1.2-0.8l0.8-0.8l0.2-2.3l-0.4-2.6l-0.9-1.6l-2.6-2.8l2.9,0.2l5.5,0.4l2.2,1.6l0.7,1.7l0,3.1l0.5,1.4l1.3,1.1
		l1.7,0.5l3.3,0.1l0.8,0.5l0.4,0.6l-0.1,0.8l-0.5,1l-0.2,0l-0.2,0.1l-0.2,0.1l-0.1,0.1l-0.3,0.3l-0.1,0.4l0.1,0.4l0.3,0.4l2,2
		l0.5,0.7l0.5,1.2l0.2,1l0.1,0.9l1.4,4.9l1.2,1.8l2,0.2l1.6-1.2v-1.6l-0.7-1.9l-0.3-2.2l0.4-3.2l0.6-1.7l2.3-2.9l0.3-2.8l0.9-1.4
		l1.3-0.9l1.3-1.4l3.6-6.5l1.2-1.2l3.4-1.9l1.4-1.2l0.4-0.2l1,0l0.5-0.3l0.2-0.7l-0.1-0.7l-0.2-0.6l0-0.4l0.3-0.8l0.2-0.9l0.4-0.9
		l0.9-0.7l3.2-2.7l4.6-1.4l8.6-0.6l6.1,1.1l1.5-0.2l1.3-1.2l2.5-3.9l1.5-1.5l1.9-0.7l1.8-0.1l2.1,0.3l0.7-0.3l1.6-1.1l1-0.2l1,0.3
		l10.6,6.5l3.9,0.2l0.9-0.9l2.5-2.5l1.6-1.9l0.7-3.3l0.7-7.3l1-0.7l1-0.2l1,0.2l0.9,0.7l1,0.3l1.1,0.1l1.1-0.1l1-0.3h0l0,0l3.2-1.1
		l6.1,0.4l2.8-2.1l1.7-3l3.8-10.2l2.2,0.2l1.9,1.3l1.9,0.8l2.4-1.2l1.2-3.7l0.6-1.1l4.3-3.1l0.7-0.7l0.1-0.7l0.3-0.2l1.4,0.5
		l0.6,0.7l1.6,2.7l0.8,1l2.6,1.3l2.1-0.5l1.9-1.8l2-2.5l1.1-0.6l0.7-0.8l0.1-1.1l-0.7-1.4l1.9-1.4l1.5-2l0.5-2.4l-1.3-2.8l4.7-2.8
		l5.2-1l10.3,0.1l2.2-0.4l4.4-1.6l2.5-0.3l5.3,0.6l2.1-0.4l3.3-2.1l0.7,0l0.7-0.2l0.8-1.1l0.1-1l-0.3-1.3l-0.1-1.5l0.5-1.8l1.1-1.3
		l1,0l1.1,0.3l1.4-0.2l1.2-0.9l1.8-2.2l1.2-0.9l2.2-0.5l4.2,0.5l2.2-0.1l17.9-8.2l1.4-1.3l2.1-3.2l1.2-1.2l4.1-0.9l9,0.7l3.4-2.9
		l0.7-4.5l-3.1-1.8l-4.2-1.3l-2.9-2.9l0.1-1.9l1-0.7l0.2-0.4l-2.3-0.9l-3.9-0.5l-1.3-0.9l1.3-1.9l1.6-5.2l2.8-2.8l3.7-0.6l4.3,1.4
		l0.9,0.8l0.6,1l0.7,1l1.2,0.7l1.3,0l3.2-1.4l4.2,0.2l1.3-0.7l1-2.5l0.7,1.5l0.2,1.1l0.4,1.1l1.1,1.5l1.4,1l3.1,0.8l1.4,1.1l1.4,1.7
		l0.9,1.4l0.4,1.8l-0.2,2.7l-0.9,2.2l-1.4,2.6l-0.6,2.2l1.3,1l6.4-2.6l1.4,0l0.6,2.1l-0.7,2.4l-1.9,4.6l-0.4,3.2l0.6,1.4l4,1.1
		l1.6,0.9l4.4,3.3l4.3,1l2.5,0.1l1.9-0.5l1.1-1.3l1.1-2.1l1.8-4.9l2.4,0.1l4.2-1.2l13.1,1l0.2-1.6l-0.1-6.3l-0.1-0.5l-0.1-0.7l0.1-1
		l0.3-0.6l1.1-1.3l0.4-0.9l-0.1-5.3l-1.7-2.5l-1.9-1.5l-0.5-2.1h0l0.5-0.6l0.6-0.5l0.7-0.3l0.7-0.1l1.7-1.2l0.5-0.8l0.3-1.5l2.2,2
		l5.8,1.3l1,1.8l1.4,1.3l1.6,0.8l1.3-0.3l0.4-1.9v0l1-2.4l1.5-1l1.3,0.7l0.5,2.8l1.1,2.7l6.5,0.1l3.1,1.5l1.8,3L377,23l-1,1.9
		l-0.8,3.3l0.3,2.6l1.1,3.2l1.4,3l1.2,2l2.3,1.8l2.1,0.9l1.7,1.4l1.4,3.4l0,1.8l-0.3,1.8l-0.1,1.7l0.7,1.5l1.4,0.5l0.2,0.1l1.3-1.3
		l1.1-1.9l1.1-1.3l4.8-0.4l9.2,3.2l13.8,4.8l1.3,0.9l1.5,2.3l0.8,0.8l1.2,0.4l9.6-2.3l2.9,0.3l0.2,1.2l0.4,1.1l5.8,9.9l0.7,0.3
		l3.2-0.1l6.8-2.6l1.9,0.3l1.5,1.5l1.5,2.8l1,2.4l0.6,2.5l-0.3,2.1l-1.7,1.3l-0.2,0.6l-0.1,0.6l0.1,0.6l0.2,0.5l4.9-0.9l7.1-6.9
		l4.8,0.5l3.6,2.8l1.7,0.8l1.9-0.4l1.4-1.8l0.8-2.3l0.8-1.8l1.5-0.3l4.2,0.1l3.8,1l3.5,2l9.8,10.4l1,3.4l-3.6,1.5l0.4,1.4v0.6
		l-0.3,0.5l-0.5,1l-0.2,0.9l-0.2,1.4l0,1.1l0.3-0.1l-1,1.4l-2.4,2.3l-1.1,1.5l-2,0.4l-3.7-1l-1.8,0.6l-0.9,2.3l-1.7,1.5l-2,1.2
		l-1.8,1.5l-0.6,0.3l-1.1,0.7l-0.7,0.1l0.6,2l0.2,0.5l-2.3-0.5l-0.9,2.9l1.1,3.2l3.7,0.7l1.5,4.6l0.5,1l1.5,1.2l0.8-0.2l0.8-0.7
		l1.3-0.4h5.2l1.1,0.3l0.8,1.7l0.1,1.7l-0.1,1.6l0.3,1.6l2,2.7l5.1,1.9l2.6,1.8l1.7,2.3l2.9,5.3l1.7,2.3l1.5,2.6l3.5,3.6l1.2,3.4
		l1.3,6.1l1.1,2.2l2,2.6l4,3.6l4.8,2.5l4.7-0.3l3.5-4.8l2.6-1.8l1.4-2l1.2-2.4l2.1-3.1l2.6-2.2l2.3-0.3l2.3,0.2l1.4-0.4l1.3-0.3
		l1.1-1l1.9-2.7l1.4-1l1,0l2.1,0.8l1-0.1l0.3-0.7l0-2.2l0.3-0.5l1.7,0l0.7,0.5l0.5,1.1l1.2,1.8l1.4,0.5l0.7-1.5l-0.2-2.4l-1.5-2
		l-0.2-4.4l-1.2-3.2l-2.1-2.3l-2.8-1.6l-2.1-2.2l-1.1-3.5l-0.9-3.8l-1.3-3.3l-3.7-5.4l0-2.5l3.1-1.9l2.6,0.3l3.2,1.9l1.7,1l2.7-0.3
		l11.8,3.3l1.3,0.8l2.7,2.2l1.5,0.8l1.6,0l3.5-0.7l1.3,0.4l-0.2,0.5l0.4,2l0.7,2.3l0.7,1.6l1.1,1.2l4.1,2.6l2.7,0.8l5.4-0.2l2.4,1
		l-2.1,0.9l0.3,0.7l0.4,0.6l0.5,0.5l0.5,0.5l-0.1,2.3l0.2,2.3l0.8,1.8l1.5,1.2l1.2-3.3l2.5-0.2l2.5,2l1.1,3.3l0.6,0.4l0.6,0.1
		l0.6-0.1l0.6-0.4l3.4-4.1l5.5-0.5l10.3,1.8l2.7-0.7l2-1.6l3.4-4.7l0.6-1.5l0.3-1.2l0.7-0.5l1.9,0.7l0.6,0.6l2.1,2.9l0.3,0.4
		l0.1,0.5l-0.1,0.5l-0.3,0.6l-1.2,3.4l-0.2,1.6l0.3,1.8l1.2,1.2l1.8,3.3l-1.1,2.7l-2.3,2l-4.9,2.9l-3.1,1.2L645,163l-1.3,0.6
		l-0.8,1.8l0.2,1.3l5.6,7.1l1.8,0.8l1.2-0.2l1-0.6l1.2-0.3l1.5,0.4l3.1,1.6l1.6,0.3l0,0.2l0,0.2l0,0.1l0,0.1l-1.1,0.9l-0.4,1l0.4,1
		l1.1,1.1l1.3,1.5l0.8,2l0.6,2.2l0.9,2.1l1.3,1.8l7.4,7.4l4.2,0.9l4.2-1.3l3.4-3l2.1-0.5l4.6-0.1l3.7-1.1l0.6-0.8l-0.2-1.8l-0.8-1.2
		l-1,0l-0.9,0.3l-0.6-0.3l-0.5-4l1.9,0.5l1.6,0.4l6.7,4.4l-0.7,3.3l1.5,1.7l7.3,1.4l1.7,1l3.6,3.1l1.4,0.6l2.7,0.6l1.4,1l0.5,1
		l0.8,2.8l0.6,1.2l2.3,1.9l1.7-0.3l3.2-2.8l-0.1-0.3l-0.2-0.2l-0.2-0.1h-0.2l4.6-0.6l4.4,1.6l8.5,4.7l2.7,0l4.5-2.9l1.7,1l0.7,3.2
		l-0.3,2.7l0.5,2.2l3.1,1.6l-3.2,3.7l0,4.8l2,5.4l2.7,5.6l1.2,5.2l0.7,1.3l1.5,1.1l3.5,0.3l1.6,0.5l1.5,1.2l2.3,2.7l1.4,0.9l1.7-0.1
		l1.8-0.8l1.8-0.3l1.7,1.3l0.5,1.4l0.8,6.1l0.5,1.7l1.4,3.1l0.5,1.6l1.7,7.5l0.3,3.7l-0.5,2.9l-4.7,0.1l-2.2,0.8l-4.7,4.3l-3.2,0.2
		l-3.3-1l-3.8-1.9l-0.8-0.2l-0.8,0.2l-3.3,1.7l-5.1-0.7l-2.4,1.5l-0.6,1.4l-0.3,3.3L751,291l-1,1l-3.8,2l-4.9,6l-2.6,1.7l-3.3-0.8
		l-0.1,2.1l-0.2,6.2l-2.7,2.1l-4.3,3.3l-15.5,6l-3.4,3.2l-2.8,4.5l-2.2,5.3l-1.5,5.8l-0.4,4.7l0,1.1l0.1,7.8L702,357l-2.4,4
		l-3.4,3.2l-3.8,1.8l-6.2-0.5l-2.8,0.4l-2.4,1.6l-1.5,3.1l-0.7,2.6l-0.9,5.4l-0.9,2.4l-2.1,2.1l-2.2,0.1l-2.4-0.4l-2.8,0.5l-1.9,1.5
		l-4,6.8l-2,1.7l-9.3,3.7l-12.8,5l-4.2-0.7l-3.5-3.7l-1.7-0.8l-2.7,0.8l-2.1,1.4l-2.2,1.5l-2,0.4l-13.1-6.6l-5.5-1.4l-5.1,1.1
		l-6.2,4.5l-1,1.6l-0.7,3.3l-1.7,2.1l-4,3.4l-2.8,4.8l-5.8,16.7l-1.3,2.4l-1.8,2.2l-0.3,3.3l-4-4.6l-1.6-11.5l-2.7-3.8l-1.9-0.1
		l-4.4,1.6l-2.2,0.3l-2.1-0.8l-4.2-2.8l-1.8-0.6l-4.4,0.2l-1.8-0.3l-1.5-1.1l-0.8-2.1l-0.4-2.5L535,408l-2.2-0.9l-2.1-0.3l-6.9-2
		l-2-1.3l-1.1-0.4l-1.1,0.1l-2.1,0.9l-1.2,0.1l-3.3-0.5l-2,1.3l-5.8,10l-1.3,1.2l-1.8,0.5l-0.6-0.2l-1.5-1.2l-2.4,0.4l-0.1,0.1
		l-16.1-2.4l-10.6,0.6l-6.1-2.6l-11.6-8.4l-0.2-0.9V401l-0.6-0.7l-1.9,0.5l-0.1-1.8l-0.9-0.8l-1.3-0.1l-1.4,0.3l1.2-1.6l-1,0.1
		l-0.9-0.4l-1.3-0.9l-2.2-0.2l-0.8-0.3l-0.6-0.9l-0.5-2.1l-0.7-0.5l-3.5,0l-1,0.2l-4.1,2.2l-1.9,0.6H427l-2.6-0.6l-8.9-5l-6.8-2.7
		l-2.6-1l-5.7-4.9l-2.5-1.4l-5.1-1.7l-5.5-2.8l-2.6-0.7l-5.4-0.1l-0.6-0.4l-0.5-0.9l-0.6-0.7l-0.9,0l-0.5,0.7l-0.3,2.3l-0.4,0.9
		l-1.6,1.9l-0.6,0.4l-8.8,2.4l-2.3,0l0.8-2.5l0-2.4l-0.5-2.4l-0.8-2.5l-8.9-0.7l-6.4-2.2l-2.5,0.3l-0.4,0.3l-0.2,0.6v0.8l0.1,0.9
		l-1.6,3.7l0,5l0.5,5.3l0,4.8l-2.4,10.9l-0.8,2.2l-0.2,1.2l0.2,1.1l0.5,1.4l0.5,1.1l0.2,0.3l-1.3,3l-2.3,0.2l-5.9-2.4l-6-0.8
		l-1.7,0.7l-0.7,1.7L322,412l-1.2,2l-0.6,3.3l-6,5.3l-2.2,3.5l-0.4,4.7l0.1,5.3l-0.6,4.5l-0.5,0.5l-1.8,1.8l-1.9-0.1l-0.9-1.1
		l-0.7-1.6l-1.2-1.6l-0.7-0.2l-1.8,0.6l-0.9-0.1l-0.4-0.7l0-1.2l-0.1-1.1l-0.7-0.6l-1.8,0.1l-3.4,1.7l-1.7,0.4l-1.4-0.3l-4.6-2.4
		l-2.8-3.2l-1.7,1.2l-2.6,6.8l-1.8,1.9l-6,2.6l-2.3,1.9l-0.9,0.4l-1.1,0.1l-11.4-4l-16.3-1.8l-3.9-1.6l-1-0.9l-2-2.5l-0.5-0.9
		l-0.5-2.4l0.2-0.5l0.7-0.2l0.8-1.6l0.3-0.3l0.6,0.1l0.5-0.2l0.2-1.1l-0.3-1.1l-0.7-0.5l-0.8-0.3l-0.6-0.5l-0.7-1.4l-1.9-2.9l-1-1.1
		l-7.7-4.3l-2.5-2.1l-2.1-1.6l-2.2-1.2L210,410l-2.2-2.3l-5.5-11.1l-2.7-4.2l-1.6-0.9l-4.3-0.7l-2.2-1l-2-2l-1.8-2.7l-1.2-3.2
		l-0.3-3.3l-2.1,0.2l-2-1.1l-1.9-1.6l-2-1.2L176.3,374.6L176.3,374.6"/>
</g>
</svg>
  </div>
</template>
<script >
export default {
  props:{
    rozpoctySK:{},
    rozpoctyCZ:{},
    rozpoctyCelkom:{},
  },
  data(){
    return{
    }
  },
  computed:{
  },
  methods:{
    // calculateColorShade(percent) {
    //   if (percent===0){
    //     console.log('prisla 0')
    //   }
    //   const startColor = [255, 249, 242];
    //   const endColor = [202, 184, 176];
    //   const color = startColor.map((startChannel, index) => {
    //     const endChannel = endColor[index];
    //     const channelDiff = endChannel - startChannel;
    //     return Math.round(startChannel + channelDiff * (percent / 100));
    //   });
    //   const colorHex = `#${color.map(c => c.toString(16).padStart(2, '0')).join('')}`;
    //   return colorHex;
    // }
  }
}
</script>
<style>
.sda{
  background: #f1cd3f;
}
</style>