<style>
.col {
  padding: 0;
  margin: 0;
}

.row {
  padding: 0;
  margin: 0;
}

.home {
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}
.golden_text_bg{
  padding: 0;
  margin: 0;
  background-color: #0b1826;
}
.golden_text_span{
  font-family: 'Times New Roman',serif;
  font-size: calc(1.5vw + 1.5vh);
  line-height: calc(1.5vw + 1.5vh);
  font-weight: bold;
  background-image: linear-gradient(
      to right,
      #462523 -10%,
      #cb9b51 22%,
      #f6e27a 47%,
      #f6f2c0 49%,
      #f6e27a 50%,
      #cb9b51 97%,
      #462523 100%
  );
  color:transparent;
  -webkit-background-clip:text;

}
</style>

<template>

  <div class="home" style="display: flex">
    <div style="position:fixed; height: 100vh;width: 100vw;top: 0;left: 0">
      <img v-bind:src="require('@/assets/4.png')" style=" height: 101vh;
      width: 100%;"/>
    </div>

    <div style="width: Calc(100% / 3);height: 100vh;z-index: 3">
      <div v-for="(item,i) in col1" :key="'field'+i" style="height: Calc(100vh / 4)">
          <new-card :card-index="item.id" :card-object="item"></new-card>
      </div>
    </div>
    <div style="width: Calc(100% / 3);height: 100vh;z-index: 3">
      <div v-for="(item,i) in col2" :key="'field'+i" style="height: Calc(100vh / 4)">
          <new-card :card-index="item.id" :card-object="item"></new-card>
      </div>
    </div>


    <div style="width: Calc(100% / 3);height: 100vh;z-index: 3">

      <div style="display: flex;height: Calc(100vh / 2);width: 100%;padding-left: 1.7vw;">
          <div style="width: 40%">
            <v-img style="height: 100%;z-index: 10;aspect-ratio: 1/1"  contain :src="require('../assets/logo-happypoker.png')"/>
          </div>
          <div style="width: 100%;height: 100%;position: relative;">
            <div style="position: absolute;top: 50%;;transform: translateY(-50%);display: block">
              <p style="color: white; font-size: calc(0.5vw + 0.5vh + 1vmin)"><v-icon left size="50" color="white">mdi-facebook</v-icon><v-icon left size="50" color="white">mdi-instagram</v-icon>happypoker</p>
              <p style="color: white; font-size: calc(0.8vw + 0.8vh + 1vmin);width: 100%;text-align: center">0907 256 994</p>
            </div>
          </div>

<!--        <div style="width: 50%;height: 100%;">-->
<!--          <v-row justify="center" align="start" >-->
<!--            <v-col cols="12" class="ma-0 pa-0" style="text-align: start; font-size: Calc(2rem)">-->
<!--              <span class="golden_text_span">Royal Flush</span>-->
<!--            </v-col>-->
<!--            <v-col cols="12" class="ma-0 pa-0" style="text-align: start; font-size: Calc(2rem)">-->
<!--              <span class="golden_text_span">Straight Flush</span>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </div>-->
<!--        <div style="width: 50%;height: 100%;">-->
<!--          <v-row justify="center" align="start" >-->
<!--            <v-col cols="12" class="ma-0 pa-0" style="text-align: start; font-size: Calc(2rem)">-->
<!--              <span class="golden_text_span strikethrough" >400 €</span>-->
<!--            </v-col>-->
<!--            <v-col cols="12" class="ma-0 pa-0" style="text-align: start; font-size: Calc(2rem)">-->
<!--              <span class="golden_text_span strikethrough" >200 €</span>-->
<!--            </v-col>-->

<!--          </v-row>-->
<!--        </div>-->
      </div>
      <div style="height: Calc(100vh / 4);width: 100%;display: flex; padding-left: 20px">
                <div style="width: 50%;height: 100%;">
                  <v-row justify="center" align="start" >
                    <v-col cols="12" class="ma-0 pa-2" style="text-align: start; font-size: Calc(2rem)">
                      <span class="golden_text_span">Royal Flush</span>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0 pt-4"  style="text-align: start; font-size: Calc(2rem)">
                      <span class="golden_text_span">Straight Flush</span>
                    </v-col>
                  </v-row>
                </div>
                <div style="width: 20%;height: 100%;">
                  <v-row justify="center" align="start" >
                    <v-col cols="auto" class="ma-0 pa-2"  style="text-align: end; font-size: Calc(2rem)">
                      <span class="golden_text_span " >200 €</span>
                    </v-col>
                    <v-col cols="auto" class="ma-0 pa-0 pt-4 " style="text-align: start; font-size: Calc(2rem)">
                      <span class="golden_text_span  " >100 €</span>
                    </v-col>

                  </v-row>

                </div>
      </div>

      <div v-for="(item,i) in col3" :key="'field'+i" style="height: Calc(100vh / 4)">
          <new-card :card-index="item.id" :card-object="item"></new-card>
      </div>
    </div>


<!--<div style="background: white;width: 50%;height: 100vh;position: absolute;top: 0">-->
<!--  <v-row v-for="(item,i) in col1" :key="'field'+i">-->
<!--    {{item}}-->
<!--  </v-row>-->
<!--</div>-->

<!--    <div style="width: Calc(100% / 3);height: 100vh;background: white;color: black">-->
<!--      fields:{{fields}}-->
<!--      <new-card  :card-index="'A'" :card-object="returnCardObject('A')"></new-card>-->
<!--      <new-card  :card-index="'K'" :card-object="returnCardObject('K')"></new-card>-->
<!--      <new-card  :card-index="'Q'" :card-object="returnCardObject('Q')"></new-card>-->
<!--      <new-card  :card-index="'J'" :card-object="returnCardObject('J')"></new-card>-->
<!--      <new-card  :card-index="'T'" :card-object="returnCardObject('10')"></new-card>-->
<!--    </div>-->
<!--    <div style="width: Calc(100% / 3);height: 100vh">-->
<!--      <new-card  :card-index="'9'" :card-object="returnCardObject('9')"></new-card>-->
<!--      <new-card  :card-index="'8'" :card-object="returnCardObject('8')"></new-card>-->
<!--      <new-card  :card-index="'7'" :card-object="returnCardObject('7')"></new-card>-->
<!--      <new-card  :card-index="'6'" :card-object="returnCardObject('6')"></new-card>-->
<!--      <new-card  :card-index="'5'" :card-object="returnCardObject('5')"></new-card>-->
<!--    </div>-->
<!--    <div style="width: Calc(100% / 3);height: 100vh">-->
<!--      <div style="width: 100%;height: 40vh;z-index: 10000;color: white;;position: relative;">-->
<!--        <div style="display: flex;height: 70%">-->
<!--          <div style="width: 100%;height: 100%">-->
<!--            <v-img style="height: 100%;z-index: 10;aspect-ratio: 1/1"  contain :src="require('../assets/logo-happypoker.png')"/>-->
<!--          </div>-->
<!--          <div style="width: 100%;height: 100%;position: relative;">-->
<!--            <div style="position: absolute;top: 50%;;transform: translateY(-50%);display: block">-->
<!--              <p style="color: white; font-size: calc(0.5vw + 0.5vh + 1vmin)"><v-icon left size="50" color="white">mdi-facebook</v-icon><v-icon left size="50" color="white">mdi-instagram</v-icon>happypoker</p>-->
<!--              <p style="color: white; font-size: calc(0.8vw + 0.8vh + 1vmin);width: 100%;text-align: center">0907 256 994</p>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->


<!--        <div style="">-->

<!--        </div>-->
<!--      </div>-->
<!--&lt;!&ndash;      <new-card  :card-index="'4'" :card-object="returnCardObject('4')"></new-card>&ndash;&gt;-->
<!--&lt;!&ndash;      <new-card  :card-index="'3'" :card-object="returnCardObject('3')"></new-card>&ndash;&gt;-->
<!--&lt;!&ndash;      <new-card  :card-index="'2'" :card-object="returnCardObject('2')"></new-card>&ndash;&gt;-->


<!--    </div>-->
  </div>


</template>

<script>
import {initializeApp} from 'firebase/app'
import {getFirestore, collection, getDocs, doc, setDoc} from 'firebase/firestore'
import NewCard from "@/components/newCard.vue";
import NewRoyal from "@/components/NewRoyal.vue";
import NewStraihgtFlush from "@/components/NewStraihgtFlush.vue";

const firebaseConfig = {
  apiKey: "AIzaSyA5dMHulWJdohwRe8zHa0rWZDcShb13Riw",
  authDomain: "tester-52d13.firebaseapp.com",
  projectId: "tester-52d13",
  storageBucket: "tester-52d13.appspot.com",
  messagingSenderId: "636013657807",
  appId: "1:636013657807:web:fe99249e203c30a1f101ba"

};

initializeApp(firebaseConfig)
const db = getFirestore()
const colRef = collection(db, 'newJackpots')

export default {
  name: 'Home',
  components: {
    NewStraihgtFlush,
    NewRoyal,
    NewCard
  },
  data: () => ({
    mock:[
      {
        cardIndex:2,
        value:200,
        isActive:true,
        plo:50
      }
    ],
    loader:true,
    fields: [],
    col1:[],
    col2:[],
    col3:[]
  }),
  methods: {
    returnCardObject(cardIndex){
      return this.fields.find(card => card.id === cardIndex);
    },

    async update(id, active, value) {

      const ref = doc(db, 'newJackpots', id);
      await setDoc(ref, {
        active: active,
        value: value,
      }, {merge: true});
    },
    saveChanges() {

    }
  },
  computed: {},

  async mounted() {
    try {
      await getDocs(colRef)
          .then((snapshot) => {
            let jackpots = []
            snapshot.docs.forEach((doc) => {
              if (doc.data().shown) {
                jackpots.push({...doc.data(), id: doc.id})
              }
            })
            this.fields = jackpots.sort(
                (p1, p2) => (p1.index < p2.index) ? 1 : (p1.index > p2.index) ? -1 : 0)
          })
      this.col1 = this.fields.splice(0, 4)
      this.col2 = this.fields.splice(0, 4)
      this.col3 = this.fields.splice(0, 3)
      this.loader=false
    }
    catch (e){
      console.log(e)
    }
  }

}
</script>
